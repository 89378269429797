@import '../../styles/mixins';

.manufacturers-manufacturers-information-dialog {
  .MuiDialogContent-root {
    padding-top: 0;

    .MuiTableContainer-root {
      width: inherit;

      .MuiTable-root {
        table-layout: fixed;
        width: 100%;
      }
    }

    table {
      tbody {
        .tr-two-col-cell {
          td {
            &:first-of-type {
              text-align: right;
            }
          }
        }

        td {
          padding: 8px 6px 8px 3px;
          border: none;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          a {
            text-decoration: none;
          }
        }

        .td-single-row-val {
          padding: 0 6px 8px 3px;
        }
      }
    }
    img.manufacturers-info-img {
      position: relative;
      display: block;
      overflow: hidden;
      outline: none;
      max-width: 250px;
      max-height: 250px;
    }
  }
  .MuiDialogActions-root {
    padding: 16px 8px 20px 8px;

    button {
      min-width: 150px;
      margin: 0 auto;
    }
  }
}
