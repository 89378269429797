@import '../../styles/mixins';

.equipments-add-equipment-form {
  padding: 0 3em;

  .fob-input-btn-form-control {
    label {
      font-size: 0.8em;
      padding: 0 0 1em 0;
    }
    button {
      min-width: 0;
      max-width: 200px;
    }
  }

  .fob-date-picker {
    max-width: 100%;
  }
}

/* Mobile Styles */
.main-content-wrapper-mobile {
  .equipments-add-equipment-form {
    padding: 0 1em;
  }
}
