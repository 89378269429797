@import '../../styles/mixins';

.help-help-dashboard-content {
  padding: 4em 2em 2em 2em;

  .guides-container {
    padding-top: 3em !important;
  }
}

.search-input-container {
  min-width: 50%;
  max-width: 50%;
}

.fob-search-input-container {
  width: 100%;

  .MuiFilledInput-root {
    background-color: white;
    padding: 0.5em;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);

    > input {
      padding: 0.5em 0 0.5em 1em;
      color: darken($fontColor, 40%);
      background-color: white;
    }

    &:hover {
      background-color: white;
    }

    &::after {
      border-bottom: none;
    }
  }
}


.support-card-item {

  .MuiPaper-root {
    width: 100%;
    height: 180px;
  }

  .MuiCardContent-root {
    justify-content: center;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  p {
    font-size: 0.75em;
    color: $fontColor;
  }

  .MuiSvgIcon-root {
    color: $primaryColor;
    font-size: 4em;
  }
}

/* Mobile styles */
.main-content-wrapper-mobile {
  .support-card-item {
    .MuiPaper-root {
      height: auto;
    }
  }
}