@import '../../styles/mixins';

.common-my-select {
  .MuiInputBase-root {
    padding-left: 8px;
  }
}

.common-my-select-without-label {
  margin-top: 15px;
}