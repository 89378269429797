.rounded-btn {
  border-radius: 18px !important;
}

.dark-btn {
  color: $fontColorContrast !important;
  //background-color: #212a3e !important;
  background-color: darken($fontColor, 20%) !important;
  text-transform: capitalize !important;
}

.dark-blue-btn {
  color: $fontColorContrast !important;
  background-color: #212a3e !important;
  text-transform: capitalize !important;

  &:disabled {
    color: $fontColorContrast !important;
    background-color: #212a3e !important;
  }
}

.active-btn {
  color: $fontColorContrast !important;
  background-color: #F51162 !important;
  text-transform: capitalize !important;

  &:disabled {
    color: $fontColorContrast !important;
    background-color: #BBBCBE !important;
  }
}

