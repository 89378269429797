@import '../../styles/mixins';
@import "../../styles/var";


.common-page-header {
  display: flex;
  flex: 1;
  height: 150px;
  background-color: $primaryColor;
  padding: 1.5em;
  color: $fontColorContrast;

  .MuiBreadcrumbs-root {
    color: inherit;

    li {
      color: inherit;
      font-size: 0.6em;
      text-transform: uppercase;
      font-weight: 400;
      color: transparentize($fontColorContrast, 0.10);

      p {
        color: inherit;
        font-size: inherit;
        text-transform: inherit;
        font-weight: 600;
      }
    }
  }
}

/* Mobile styles */
.main-content-wrapper-mobile {
  .common-page-header {
      padding-top: 3em;
      height: 135px;
  }
}
