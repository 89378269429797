@import '../../styles/mixins';

.react-player__preview {
    background-color: #3f3833;
//    background: url(../images/VideoImage.png) bottom center no-repeat;
//    background-size: contain !important;
}

.equipments-activity-details-add-dialog-content {
    table {
        height: 100%;
        width: 100%;
        table-layout: fixed;
    }

    .activity-img-wrapper {
        position: relative;
        .activity-img-input {
            width: 0;
            height: 0;
            overflow: hidden;
            opacity: 0;
        }
        .activity-img {
            width: 100%;
            height: auto;
            overflow: hidden;
            background-color: #ffffff;
            .avatar-loading {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 2;
                background-color: rgba(255, 255, 255, 0.5);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            img {
                width: 100%;
                height: auto;
                font-size: 2.5em;
            }
        }
        .activity-img-edit-btn {
            position: absolute;
            right: -1em;
            top: -1em;
            opacity: 0;
            transition: opacity 0.2s;
        }
        &:hover {
            .activity-img-edit-btn {
            opacity: 1;
            }
        }
    }
}
