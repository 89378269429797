@import '../../styles/mixins';

.profile-my-profile-form-header {
  width: 100%;
  > .MuiGrid-root {
    position: relative;



    .change-cover-btn {
      background-color: rgba(0,0,0, 0.5);
      position: absolute;
      top: 16px;
      right: 16px;
      color: darken(white, 10%);
      padding: 0.5em 0.8em;
      border-radius: 16px;
      font-size: 0.75em;
    }

    .user-name {
      position: absolute;
      bottom: 8px;
      left: 180px;
      text-transform: uppercase;
      color: white;
    }

    .cover-photo {
      height: 180px;
      width: 100%;
      border-radius: 0.5em;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
      .user-bg-input {
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
      }
      .user-bg-edit-btn {
        position: absolute;
        right: 0.5em;
        top: 0.5em;
        opacity: 0;
        transition: opacity 0.2s;
      }
      &:hover {
        .user-bg-edit-btn {
          opacity: 1;
        }
      }
      .cover-loading {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .user-avatar {
      position: absolute;
      bottom: -30px;
      left: 45px;
      height: 85px;
      .user-avatar-input {
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
      }
      .user-avatar-img {
        border-radius: 50%;
        width: 85px;
        height: 85px;
        overflow: hidden;
        background-color: #ffffff;
        .avatar-loading {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 2;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .MuiAvatar-root {
          height: 100%;
          width: 100%;
          font-size: 2.5em;
        }
      }
      .user-avatar-edit-btn {
        position: absolute;
        right: -1em;
        bottom: -1em;
        opacity: 0;
        transition: opacity 0.2s;
      }
      &:hover {
        .user-avatar-edit-btn {
          opacity: 1;
        }
      }
    }
  }
}

#change-cover-file {
  display: none;
}