@import '../../styles/mixins';

.equipments-equipment-details-header {
  .info-icon {
    width: 18px;
    height: auto;
  }

  .equipments-equipment-details-header-column-1, .equipments-equipment-details-header-column-2 {
    //max-width: 30vw;
  }

  .equipments-equipment-details-header-grid-item {
    min-height: 200px;

    &:nth-of-type(2) {
      padding: 0 8px;
      border-right: 1px inset rgba(0,0,0,0.10);
    }

    &:nth-of-type(3) {
      padding: 0 8px;
    }

    .equipments-equipment-details-header-td-btn-right {
      float: right;
      padding-right: 8px;
    }
  }

  .user-profile-space-container {
    border-right: 1px inset rgba(0,0,0,0.10);
  }

  .equipments-equipment-details-header-column-2 {
    table {
      //width: 30vw;
      //table-layout: fixed;
      //
      //td:nth-of-type(2) {
      //  white-space: nowrap;
      //  overflow: hidden;
      //  text-overflow: ellipsis;
      //}
    }
  }

  header {
    > .MuiToolbar-root {
      padding: 1em 2em;
      align-items: flex-start;

      .MuiDivider-vertical {
        margin: 2em 1.25em;
      }

      table {
        width: 100% !important;
        table-layout: fixed;

        tr {
          td {
            padding: 0.25em 0;
          }

          td:nth-of-type(2) {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
          }
        }
      }

      .header-actions {
        margin: -100px 0 8px 0;
        justify-content: center;
        display: flex;
        flex-direction: column;

        .MuiButton-root {
          margin: 8px 0;
          width: 80%;
          max-width: 220px;
          min-width: 220px;
          padding: 0.5em 1em;
          justify-content: flex-start;
        }
      }
    }
  }
  .equipments-equipment-details-header-date-field {
    display: flex;
    flex-direction: row;
    .fob-date-picker {
      margin-top: 0;
      margin-bottom: 0;
      .MuiInputBase-formControl {
        padding-right: 0;
        .MuiInputBase-input {
          padding-right: 0;
        }
        .MuiInputAdornment-positionEnd {
          .MuiIconButton-root {
            padding: 0
          }
        }
      }
    }
  }
  .equipments-equipment-details-header-owner-field {
    display: flex;
    flex-direction: row;
    width: calc(100% - 36px);
  }
  .equipments-equipment-details-header-serial-field {
    display: flex;
    flex-direction: row;
    width: calc(100% - 36px);
  }
}

/* Mobile styles */
.main-content-wrapper-mobile {
  .equipments-equipment-details-header {
    .equipments-equipment-details-header-grid-item {
      min-height: 70px;
    }
    header {
      >.MuiToolbar-root {
        .header-actions {
          margin: 8px 0 8px 0;
        }
      }
    }
  }
}
