@import '../../styles/mixins';

.common-loading-button {
  @extend .rounded-btn;
  min-width: 130px !important;

  .MuiCircularProgress-root {
    width: 20px !important;
    height: 20px !important;
  }
}
