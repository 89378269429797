@import './../var';

.fob-search-input-container {
  //color: $fontColor;

  .MuiFilledInput-adornedStart {
    padding-left: 8px;
  }

  .MuiFilledInput-root {
    border-radius: 2em;
    border: 1px solid lighten($fontColor, 30%);

    .MuiInputAdornment-root {
      color: $fontColor;
    }

    > input {
      padding: 0.5em 0 0.5em 1em;
      color: darken($fontColor, 40%);
      border-radius: inherit;
    }

    &::after {
      border-bottom: none;
    }
  }
}

.fob-search-input-contrast {
  .MuiFilledInput-root {
    border: 1px solid lighten($fontColor, 80%);
    background-color: $fontColorContrast;

    &:hover {
      background-color: $fontColorContrast !important;
    }
  }

  .MuiFilledInput-root.Mui-focused {
    background-color: $fontColorContrast !important;
  }
}