@import '../../styles/mixins';

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  &.qr-code-loading {
    position: static;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  &.page-content-loading {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
  }
}
.authentication-authentication-index {
  height: inherit;

  background: url('./../images/BackgroundRed@2x.png') no-repeat center center fixed;
  background-size: cover;
  overflow: hidden;

  .authentication-grid-item {
    max-height: 80%;
    max-width: 80%;
    min-height: 580px;
  }

  .authentication-content {
    display: flex;
    flex: 1;
    background: url('./../images/auth_intro_bg.png') repeat-y center center;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    border-radius: 1em;

    .authentication-content-right {
      padding: 1em 4vw;
      margin-left: -10px;

      //padding: 1em 4em;
      background-color: white;

      .authentication-header-content {
        margin: 0 0 1em 0;
        &.authentication-header-content-xs {
          margin-bottom: 0.25em;
        }
      }

      .authentication-form-container {
        position: relative;
        > form {
          > .MuiGrid-root {
            .authentication-form-grid-row {
              .authentication-form-grid-cell {
                padding: 8px 0;
              }
              &.error-xs-style {
                position: fixed;
                top: 0;
                align-items: center;
                justify-content: center;
              }
            }

            .authentication-form-grid-multiple-cells-row {
              .authentication-form-grid-cell {
                &:first-of-type {
                  padding-right: 8px;
                }

                &:not(:first-of-type) {
                  padding-left: 8px;

                }
              }
            }

            .authentication-form-grid-multiple-cells-row-xs {
              .authentication-form-grid-cell {
                &:first-of-type,
                &:not(:first-of-type) {
                  padding-right: 8px;
                  padding-left: 8px;
                }
              }
            }
          }

          .authentication-form-main-btn {
            border-radius: 1em;
            text-transform: capitalize;
            margin: 1.5em 0 1em 0;
          }

          .authentication-form-question-opt {
            color: $fontColor;
            font-size: 0.85em;
            padding: 1em 0 0 0;

            a {
              color: $primaryColor;
              text-decoration: none;
            }
          }

          .authentication-forgot-password {
            color: $primaryColor;
            text-decoration: none;
          }
        }
      }

      .authentication-btn-group {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        margin: 0 0 8vh 0;

        .MuiToggleButtonGroup-root {
          border-radius: 2em;
          background: none;

          button {
            background-color: lighten($fontColor, 30%);
            text-transform: capitalize;
            padding: 0 1em;
            width: 100px;
            color: #222222;
            border: none;

            &:first-of-type {
              border-top-left-radius: 2em;
              border-bottom-left-radius: 2em;
            }

            &:last-of-type {
              border-top-right-radius: 2em;
              border-bottom-right-radius: 2em;
            }
          }

          button.Mui-selected {
            background-color: $primaryColor;
            color: $fontColorContrast;
          }
        }
      }
    }
  }

  .authentication-copyright {
    color: $fontColorContrast;
    text-align: center;
    padding: 6vh 0 1em 0;
  }


  .authentication-grid-item.MuiGrid-grid-xs-12 {
    .authentication-content {
      .authentication-content-right {
        margin: 0;

        .authentication-btn-group {
          margin: 0 0 2em 0;
          &.authentication-btn-group-xs {
            margin-bottom: 1em;
          }
        }
      }
    }
    .authentication-copyright {
      padding: 1em 0 0 0;
      font-size: 0.8em;
    }
  }
}