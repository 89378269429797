@import '../../../../styles/mixins';

.dashboard-chart-2d-doughnut-paper {
  width: 100%;
  background-color: #fafbfc;
  min-height: 250px;

  .dashboard-chart-2d-doughnut-paper-grid {
    padding: 1rem 0.5rem;

    > .MuiGrid-item {
      padding: 0.5rem;
    }
  }

  .dashboard-chart-2d-doughnut-paper-toggle-frequence-container {
    padding: 1rem;
  }

  .dashboard-chart-2d-doughnut-paper-bottom-title {
    color: $primaryColor;
    padding: 0 0 1rem;
  }

  .dashboard-chart-2d-doughnut-paper-filter-container {
    display: flex;
    flex: 1;

    .MuiIconButton-root {
      padding: 6px 12px;
    }
    .MuiInputBase-input {
      //max-width: 170px;
      //max-width: 180px;
    }
  }

  .DateRangePicker {
    .DateRangePickerInput {
      border-radius: 4px;
      box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);

      .DateInput_input {
        padding: 8px 0;
      }

      .DateInput__small {
        max-width: 70px;
      }
    }
  }

  .DateRangePickerInput_calendarIcon {
    padding: 0;
  }
}
