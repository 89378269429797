@import '../../styles/mixins';

.equipments-add-address-dialog {
  .two-input-row-grid {
    > .MuiGrid-item {
      &:first-of-type {
        padding-left: 0;
        padding-right: 10px;
      }
      &:last-of-type {
        padding-right: 0;
        padding-left: 10px;
      }
    }
  }
}

/* Mobile styles */
.MuiDialog-root-mobile {
  &.equipments-add-address-dialog {
    .two-input-row-grid {
      > .MuiGrid-item {
        &:first-of-type {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 16px;
        }
        &:last-of-type {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
}
