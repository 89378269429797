@import '../../styles/mixins';

.search-search-result-table {
  .manufacturer-search-select {
    min-width: 150px;
  }

  .search-input-form {
    width: 100%;
  }
}

.search-input-ext {
  .MuiInputBase-root {
    background-color: white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    input {
      padding-left: 8px;
    }
  }
}

/* Mobile styles */
.main-content-wrapper-mobile {
  .search-input-form {
    padding-top: 1.5em;
  }
}
