@import '../../styles/mixins';

.roles-role-details-dialog {
  > .MuiDialog-container {

    > .MuiDialog-paper {
      max-width: 700px;
      width: 100%;
    }

    .MuiDialogContent-root {
      overflow: hidden;
    }

    .MuiDialogActions-root {
      justify-content: center;
      padding: 2em 0 2.5em 0;

      .MuiButton-root {
        width: 70%;
        max-width: 180px;
        margin: 0 0.75em;
      }
    }

  }
}
