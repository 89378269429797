.fob-upload-dialog {
  > .MuiDialog-container {
    > .MuiDialog-paper {
      max-width: 100%;
      min-width: 560px;
    }
  }

  .MuiDialogActions-root {
    justify-content: center;
    padding: 2em 0 2.5em 0;

    .MuiButton-root {
      width: 70%;
      max-width: 180px;
      margin: 0 0.75em;
    }
  }
}

/* Mobile styles */
.MuiDialog-root-mobile {
  &.fob-upload-dialog {
    > .MuiDialog-container {
      > .MuiDialog-paper {
        max-width: 100%;
        min-width: 0;
      }
    }
  }
}