$primaryColor: #E3475E;
$fontColor: #A99D95;
$fontColorContrast: #ffffff;

$darkBlue: #222A3E;

$toolbarHeight: 64px;
$toolbarCompanyIconHeight: 32px;

$sidenavWidth: 230px;
$sidenavWidthClosed: 60px;
