@import '../../styles/mixins';

.equipments-warranty-details-dialog {
  .MuiDialog-container {
    > .MuiDialog-paper {
      max-width: 700px;
      background-image: url('./../images/EditPaper@3x.png');
      background-repeat: no-repeat;
      background-position: 10% 85%;
      background-attachment: initial;
      background-size: 35%;
    }
  }
}
