@import '../../styles/mixins';

.equipments-add-equipment {
  .equipments-add-equipment-body {
    > .MuiGrid-root {
      padding: 2.5em 1em 1.5em 1em;

      > .MuiGrid-item {
        padding: 1em;
      }
    }
  }

  .MuiButton-root {
    justify-content: start;
  }

  .equipments-add-equipment-warranty-service-section {
    .form-control-btn-group {
      &:first-of-type {
        padding: 0 0 1em 0;
      }

      &:last-of-type {
        padding: 1em 0 0 0;
      }

      label {
        padding: 0 0 1em 0.5em;
      }
      .MuiButton-root {
        width: 100%;
      }
    }
  }


  .fob-user-details-container {
    min-height: 100px;
    border-right: 1px solid lighten($fontColor, 30%);
  }

  .equipments-add-equipment-action-btns {
    padding: 2em 2em 0 2em;

    .MuiButton-root {
      justify-content: center;
    }
  }
}

/* Mobile Styles */
.main-content-wrapper-mobile {
  .equipments-add-equipment {
    .equipments-add-equipment-body {
      > .MuiGrid-root {
        padding: 1em;
        >.MuiGrid-item {
          &.fob-user-details-container {
            min-height: 0;
            padding-right: 2em;
            padding-left: 2em;
          }
          &.equipments-add-equipment-warranty-service-section {
            padding-left: 2rem;
            padding-right: 2rem;
          }
        }
      }
    }
  }
}