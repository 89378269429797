@import '../../styles/var';
@import '../../styles/mixins';

$labelColor: $fontColor;
$underlineColor: transparentize($fontColor, 0.5);

.authentication-auth-text-field {
  .MuiInputBase-input {
    color: darken($labelColor, 30%);
  }

  .MuiFormLabel-root {
    color: $labelColor;
    font-size: 0.85em;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $labelColor;
  }

  .MuiInput-underline {
    &:hover:before {
      border-bottom: 2px solid $underlineColor !important;
    }

    &:before {
      border-bottom: 1px solid $underlineColor;
    }
    &:after {
      border-bottom: 2px solid $underlineColor;
    }

  }
}
