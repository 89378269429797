@import '../../styles/mixins';

.home-app {
}
.main-content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: auto;
    > div {
        width: 100%;
        //display: flex;
        //flex-direction: column;
        //
        //.fob-content-body {
        //    display: flex;
        //    flex: 1 0 auto;
        //}
    }
}