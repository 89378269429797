@import '../../styles/mixins';

.authentication-biometric-usage-prompt-dialog {
  height: 40%;
  bottom: -21px !important;
  top: auto !important;

  .MuiDialog-container {
    .MuiDialogTitle-root {
      padding: 0;
      text-align: center;
      z-index: 1;

      .top-icon {
        border-radius: 50%;
        background-color: red;
        color: white;
        padding: 8px;
        margin-bottom: -32px;
      }
    }

    > .MuiDialog-paper {
      margin: 0;
      max-height: 100%;
      background-color: transparent;
      box-shadow: none;
      position: absolute;
      bottom: 20px;

      .MuiDialogContent-root {
        padding-top: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        background-color: #fff;

        h3 {
          margin-bottom: 0;
          margin-top: 0.5rem;
          font-size: 1rem;
        }

        p {
          font-size: 0.75rem;

          &:first-of-type {
            margin-bottom: 0;
            margin-top: 0.5rem;
          }

          &:last-of-type {
            margin: 0.5rem 0 0 0;
          }
        }
      }
    }

    .MuiDialogActions-root {
      padding: 0;

      button {
        flex: 1;
        margin: 0;
        border-radius: 0;
      }
    }
  }
}
