@import '../../styles/mixins';

.equipments-activity-details-dialog-content {
  table {
    height: 100%;
    width: 100%;
    table-layout: fixed;
  }

  > .MuiGrid-root {
    .activity-img-wrapper {
      position: relative;
      .activity-img {
          width: 100%;
          height: auto;
          overflow: hidden;
          background-color: #ffffff;
          img {
              width: 100%;
              height: auto;
              font-size: 2.5em;
          }
      }
    }
  }

  .activity-media-carousel-container {
    width: 100%;
    height: 280px;

    > div {
      position: relative;
    }
  }

  .activity-card-file-preview {
    height: 250px;
    width: auto;
    text-align: center;

    .MuiCardMedia-root {
      height: 200px;
      width: 180px;
      padding: 8px;
      background-size: contain;
    }

    .MuiCardContent-root {
      padding: 8px 0;
      background-color: $primaryColor;
      //border-top: 4px solid $primaryColor;

      a {
        text-decoration: none;
        color: #eee;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 11px;
      }
    }
  }

  .activity-preview-files {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    li {
      max-height: 100px !important;

      img {
        max-width: 80px;
        max-height: 80px;
        height: auto;
      }
    }
  }
}
