@import '../../styles/var';
@import '../../styles/mixins';

.common-base-table {

  .common-base-table-header {
    padding: 0.5em 0;

    .MuiDivider-vertical {
      margin: 0 1.25em;
    }

    .user-profile-space {
      p {
        font-size: 0.80rem;
        font-weight: 600;
      }
    }

    table {
      tbody {
        tr {
          td {
            &:first-of-type {
              padding-right: 1em;
            }
          }
        }
      }
    }
  }

  .common-base-table-content {
    .MuiTableCell-head {
      font-weight: 600;
    }
  }

  .result-row-info {
    h3 {
      margin: 0;
      color: $primaryColor;
    }
  }
}

/* Mobile styles */
.main-content-wrapper-mobile {
  .common-base-table {
    .MuiTablePagination-root {
      width: 100%;
      .MuiTablePagination-toolbar {
        display: block;
        padding-left: 8px;
        padding-right: 8px;
        >.MuiTablePagination-caption {
          float: left;
          line-height: 2;
          &:nth-child(4) {
            float: right;
          }
        }
        >.MuiTablePagination-input {
          float: left;
        }
        >.MuiTablePagination-actions {
          display: flex;
          justify-content: center;
          clear: both;
          margin: 0;
        }
      }
    }
  }
}
