.MuiTable-root {
    &.no-border {
        .MuiTableCell-root {
            border-bottom: none;
            font-size: 1em;
            &:first-child {
                width: 30%;
            }
            &:last-child {
                width: 60%;
            }
        }
    }
    .selected-column {
        .MuiTableSortLabel-icon {
            opacity: 0.4;
        }
    }
    .not-selected-column {
        .MuiTableSortLabel-icon {
            opacity: 0 !important;
        }
    }
}