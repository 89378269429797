@import '../../styles/var';
@import '../../styles/mixins';

.common-top-nav {
  &.common-top-nav-mobile {
    position: fixed;
    width: 100%;
    z-index: 1;
    .common-top-nav-user-space {
      padding: 15px 0em 15px 1em;
    }
    header .MuiToolbar-regular {
      justify-content: space-between;
    }
  }
  header {
    > .MuiToolbar-root {
      background-color: white;
    }

    .MuiToolbar-regular {
      max-height: $toolbarHeight;
    }

    .common-top-nav-company-icon {
      height: $toolbarCompanyIconHeight;
    }

  }

  .common-top-nav-user-space {
    display: flex;
    flex-direction: row;
    padding: 15px 2.5em 10px;

    .common-top-nav-user-space-labels {
      display: inline-flex;
      flex-direction: column;
      padding: 0 8px 0;

      p {
        margin: 0;
        font-weight: 600;
        font-size: 0.8em;

        &:last-of-type {
          color: $primaryColor;
          margin: 3px 0 0;
        }
      }
    }

    .MuiAvatar-root {
      display: inline-flex;

      img {
        max-height: 30px;
        max-width: 30px;
        border-radius: 24px;
      }
    }
  }

  .common-top-nav-notifications-btn {
    background-color: lighten($fontColor, 25%);
    padding: 5px;
    margin: 8px 16px;

    .MuiIconButton-label {
      svg {
        width: 0.85em;
        height: 0.85em;
      }

      .MuiBadge-badge {
        transform: scale(1) translate(-25%, 25%);
      }
    }
  }
}
