@import '../../styles/mixins';

.activity-image-dialog {
  > .MuiDialog-container {
    > .MuiDialog-paper {
      width: 100%;
      height: 100%;
      max-width: 100%;
      overflow: hidden;
    }
  }

  .MuiDialogContent-root {
    display: flex;
    flex: 1;
    padding: 0;

    > div {
      > div {
        top: calc(50% + 40px) !important;
        height: calc(100% - 84px) !important;
        width: calc(100% - 64px) !important;
        //width: 100% !important;
      }
    }
  }
}


.activity-media-container {
  width: 100%;
  height: 100%;

  .MuiCardContent-root {
    padding: 0 !important;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      justify-self: center;
      align-self: center;
    }

    > div {
      width: 100% !important;
      height: 100% !important;
    }
  }
}