.fob-table-container {
  > .MuiPaper-root {
    header {
      box-shadow: none;
    }

    .MuiCardContent-root {
      padding: 0;

      .MuiTableContainer-root {
        box-shadow: none;
      }
    }
  }

}