@import '../../styles/mixins';

.common-toggle-group-daily-monthly {
  padding: 6px;
  background-color: #f5f5f5 !important;
  border-radius: 8px !important;

  .MuiToggleButton-root {
    background-color: transparent;
    border: none;
    color: #8e98a2;
    min-width: 130px;
    height: 38px;

    .MuiToggleButton-label {
      text-transform: capitalize;
    }
  }

  .MuiToggleButton-root.Mui-selected {
    background-color: #fff;
    color: #6c54fc;
    border-radius: 6px;
    box-shadow: 0px 2px 1px -2px transparentize(#8e98a2, 0.05);
  }
}
