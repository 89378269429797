@import '../../styles/mixins';

.admin-admin-option-dashboard {
  .fob-content-body {
    padding: 5em 4em 5em 4em;
  }
}

.admin-category-btn {
  padding: 1em;
  width: 100%;
  height: 25vh;
  margin: 1em;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .MuiPaper-root {
    width: 100%;
    height: 100%;
    background-color: lighten($fontColor, 30%);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }

  button {
    border-radius: 2em;
    margin: -1.5em 0 0 0;
    text-transform: capitalize;
  }

  img {
    width: auto;
    max-height: 120px;
  }
}
