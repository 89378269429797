.fob-details-dialog {

  .MuiDialogTitle-root {
    padding: 16px 24px 8px 24px;

    .MuiToolbar-root {
      padding: 0;
    }
  }

  .MuiDialogContent-root {
    padding: 16px 24px 8px 24px;

    > .MuiGrid-container {
      align-items: flex-start;

      > .MuiGrid-item {
        &:first-of-type {
          padding-right: 2em;
        }

        //&:last-of-type {
        //  max-width: 50%;
        //  flex-basis: 50%;
        //}
      }
    }

    table {
      padding: 0 1em 1em;
      tr {
        &:first-of-type {
          td {
            padding: 0 0.5em 0.5em 0;

            &:first-of-type {
              padding: 0 1.5em 0.5em 0;
            }
          }
        }

        td {
          padding: 0.5em 0;

          &:first-of-type {
            padding-right: 1.5em;
          }

          &:last-of-type {
            color: $fontColor;
          }

          .MuiFilledInput-input {
            padding: 10px 12px;
            //background-color: lighten($fontColor, 20%);
            &.MuiInputBase-inputAdornedStart {
              padding-left: 0;
            }
            &.MuiInputBase-inputAdornedEnd {
              padding-right: 0;
            }
          }

          .input-select {
            width: 100%;
          }
        }
      }
    }
  }

  .MuiDialogActions-root {
    justify-content: center;
    padding: 2em 0 2.5em 0;

    .MuiButton-root {
      width: 70%;
      max-width: 180px;
      margin: 0 0.75em;
    }
  }
}

.fob-form-dialog {
  .MuiDialogContent-root {
    padding: 2em 2em 3em 2em;
  }
}

/* Mobile Styles */
.MuiDialog-root-mobile {
  &.fob-details-dialog {
    .MuiDialogActions-root {
      .MuiButton-root {
        width: 100%;
      }
    }
  }
}