@import './var';
@import './mixins';

// Here you put all global css rules.

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  //color: $fontColor;
}

.fob-content-body {
  margin: -6.5em 1em 0 1em;
}

.swal2-container {
  z-index: 99999 !important;
}