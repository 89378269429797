@import '../../styles/mixins';

.common-upload-box-container {

}

.common-upload-box {
  width: 100%;
  border: dashed;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  min-height: 250px;
  border-color: #C8C8C8;
  background-color: #F0F0F0;
  border-radius:8px;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  .common-upload-box-text-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    img {
      flex: auto;
      height: auto;
      max-width: 85px;
      margin: 2.5em 0 1.5em 0;
    }

    //p {
    //  padding: 1.5em 0 0 0;
    //}
  }
}

.common-upload-box-files {

  ul {
    padding: 0 16px !important;
    list-style-type: none !important;

    li {
      position: relative;
    }
  }

  .common-upload-box-file-item {

  }

  .common-upload-box-file-item-loading {

  }

  .common-upload-box-file-item-progress-container {
    display: inline-block;
    position: absolute;
    top: 20px;
    left: auto;
    margin-left: 20px;
    width: 100%;
    max-width: 390px;

    .common-upload-box-file-item-progress-section {
      padding: 8px 0;

      .uploaded-percentage {
        float: right;
        color: $primaryColor;
      }

      .uploaded-mbs {
        color: #aaa;
      }
    }
  }
}
