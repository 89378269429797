.rounded-input {
  > .MuiInputBase-root {
    border-radius: 2em;
    &.MuiInputBase-multiline {
      border-radius: 1.125em;
      padding: 1.5em;
      margin-top: 1em;
      margin-bottom: 1em;
      .MuiInputBase-inputMultiline {
        line-height: 1.5em;
        min-height: 5em;
        font-size: 0.85em;
      }
    }
    .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
      margin-top: 0;
    }
  }
}

.fob-date-picker {
  // max-width: 230px;

  > .MuiInputBase-root {
    .MuiSvgIcon-root {
      color: $fontColor;
    }

    &:hover:before {
      border: none;
    }

    &:before, &:after {
      border: none;
    }
  }
}

.fob-filled-input-with-outside-label {
  .MuiInputBase-root {
    background-color: lighten($fontColor, 30%);

    .MuiInputBase-input{
      padding-left: 12px;
    }
  }
}

.fob-input-btn-form-control {
  flex-direction: column;

  label {
    @extend .MuiFormLabel-root;
  }
}

.MuiFilledInput-underline.Mui-disabled:before {
  border-bottom-style: none !important;
}

.fob-date-picker {
  min-width: 100%;

  input {
    padding: 7px 12px;
  }
  //.MuiInputAdornment-root {
  //  display: none;
  //}
}

.form-label-with-icon {
  margin-top: -6px;

  .MuiSvgIcon-root {
    margin-bottom: -6px;
  }
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 13px;
}