@import '../../styles/mixins';

.dashboard-dashboard {
  .dashboard-dashboard-body {
    padding: 1rem;
  }
}

.paper-select {
  .MuiSelect-root {
    padding: 8px 24px 8px 12px !important;
  }

  .MuiInput-underline:before, .MuiInput-underline:after {
    transition: none !important;
    border-bottom: none !important;
  }
}