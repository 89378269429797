@import '../../styles/mixins';

.dashboard-lifecycle-tracking {

}

.warranty-legend {
  min-width: 230px;
  padding: 12px;

  ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0;

    li {
      padding: 4px 0;
      display: flex;
      align-items: center;
      color: rgba(0,0,0,0.65);

      .warranty-legend-color {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin: 0 8px 0 0;
      }
    }
  }
}