@import '../../styles/mixins';

.equipments-activity-details-dialog {
  .MuiDialog-container {
    > .MuiDialog-paper {
      max-width: 800px;
    }
  }

}
