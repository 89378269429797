@import '../../styles/mixins';

.equipments-upload-activity-images {
  background: url(../images/activity.png) bottom center no-repeat;
  background-size: contain !important;

  .dropzone {
    min-width: 250px;
    min-height: 250px;
    position: relative;
    flex: 1;
    display: flex;

    &:focus {
      outline: none;
    }

    .activity-dropzone-label {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      visibility: hidden;
    }

    ul.activity-dropzone-files {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      li {
        max-height: 100px !important;

        .MuiGridListTile-tile {
          display: flex;
          flex: 1;
        }

        img {
          max-width: 80px;
          max-height: 80px;
          height: auto;
        }

        .activity-dropzone-file-remove {
          position: absolute;
          z-index: 2;
          right: 0;
          top: 0;
          background-color: $primaryColor;
          color: white;
          padding: 3px;

          .MuiSvgIcon-root {
            font-size: 1rem;
          }
        }
      }
    }
  }

  &:hover {
    .activity-dropzone-label {
      visibility: visible;
    }
  }
}
