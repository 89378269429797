@import '../../styles/mixins';

.common-my-date-range-picker {
  display: flex;
  flex: 1;

  .common-my-date-range-picker-input-container {
    display: flex;
    flex: 1;

    .MuiIconButton-root {
      padding: 6px 12px;
    }
    .MuiInputBase-input {
      //max-width: 170px;
    }
  }
}
