@import '../../styles/mixins';

.common-copyright {
  margin: 2em 0 1.5em 0;
  text-align: center;

  p {
    font-size: 1em;
    color: $fontColor;
  }
}
