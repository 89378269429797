@import '../../styles/mixins';

.equipments-equipment-table {
  //margin: -6.5em 1em 0 1em;

  .common-base-table-header {
    .fob-search-input-contrast {
      max-width: 40%;

      .MuiFilledInput-root {
        padding: 0;
        box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12);
      }
    }

    .header-actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }


}
