@import '../../styles/mixins';

$headerColor: lighten($fontColor, 10%);


.profile-my-profile-form {
  .profile-my-profile-form-container {
    padding: 4em 0 3em 0;

    > .MuiGrid-item {
      padding: 2em 3em 1em 3em;
    }

    .address-grid-container {
      flex-basis: unset;
      .grid-item {
        flex-basis: unset;
        max-width: 100%;
      }
    }

    .company-info-container {
      height: auto;
      padding: 0 0 20px 0;
      min-height: 0;
    }
  }

  .my-profile-form-item-group {

    .my-profile-form-item-group-header {
      color: $headerColor;

      .MuiSvgIcon-root {
        padding-right: 8px;
      }

      h6 {
        color: $headerColor;
      }
    }

    .MuiDivider-root {
      margin: 0.25em 0 1.5em 0;
    }
  }

  .my-profile-primary-actions-container {
    padding: 0;

    > .MuiGrid-item {
      padding: 0 0 0 2em;

      button {
        margin: 0 1em;
        width: 160px;
      }
    }
  }
}

/* Mobile styles */
.main-content-wrapper-mobile {
  .profile-my-profile-form {
    .profile-my-profile-form-container {
      > .MuiGrid-item {
        padding: 1em 1em 2em 1em;
      }
      .company-info-container {
        padding-bottom: 3em;
      }
    }
  }
}
