@import '../../styles/mixins';

.help-user-guide-card {
  background-color: lighten($fontColor, 35%) !important;

  .MuiCardHeader-subheader {
    color: $primaryColor;
    text-transform: uppercase;
    font-weight: 500;
  }

  .MuiCardContent-root {
    padding-top: 0;

    .MuiListItemText-primary {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
