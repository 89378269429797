@import '../../styles/mixins';

.common-side-nav {
  &.common-side-nav-mobile {
    .drawer-closed {
      > .MuiDrawer-paper {
        //overflow: initial;
        overflow: hidden !important;
      }
    }
    >.MuiDrawer-root {
      .primary-menu-bar-list-item {
        padding-bottom: 2.5rem;
        .primary-menu-bar {
          position: fixed;
          left: 0;
          background-color: white;
          border-radius: 0 10px 10px 0;
          padding: 3px 10px;
          z-index: 1;
        }
      }
      .sidepanelClosed {
        .primary-menu-bar-list-item {
          .primary-menu-bar {
            padding: 3px 24px;
          }
        }
      }
    }
  }
  > .MuiDrawer-root {
    > .MuiDrawer-paper {
      top: auto;

      .MuiList-root {
        padding: 0 0.75em 1.5em;

        > .MuiListItem-button {
          margin: 0.5em 0;
          background-color: white;
          border-radius: 6px;
          height: 46px;

          .MuiListItemText-primary {
            font-size: 0.80em;
            font-weight: 600;
          }
        }

        > .MuiListItem-button.selected {
          background-color: $primaryColor;
          color: white;

          svg {
            color: white;
          }
        }
      }
    }
    .primary-menu-bar-list-item {
      padding-top: 0;
      margin-left: -2px;
      .primary-menu-bar {
        padding: 0;
        .MuiSvgIcon-root {
          font-size: 2rem;
        }
      }
    }
  }

  .drawer-closed {
    > .MuiDrawer-paper {
      top: auto;

      .MuiList-root {
        padding: 0 0.5em 1.5em;

        .MuiListItem-button {
          .MuiListItemIcon-root {
            padding-left: 4px;
          }
        }
      }
    }
    .primary-menu-bar-list-item {
      margin-left: 2px;
    }
  }
}

