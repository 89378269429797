@import '../../styles/mixins';

.manufacturers-manufacturer-details-dialog {
  > .MuiDialog-container {

    > .MuiDialog-paper {
      max-width: 700px;
      width: 100%;
    }

    .MuiDialogContent-root {
      overflow: hidden;
    }
  }
}
